import { memo } from 'react';
import MUIButton from '@material-ui/core/Button';

import { ADVERTISEMENT } from 'app/pages/SEOStatic/utils';

import Title from './Title';
import Image from './Image';
import Button from './Button';
import Divider from './Divider';
import Description from './Description';
import Advertisement from './Advertisement';

import { usePreviewStyles } from './styles';

const contentItems = {
  image: Image,
  title: Title,
  button: Button,
  divider: Divider,
  text: Description,
  description: Description,
  advertisement: Advertisement,
};

const Preview = ({ content, onClosePreview }) => {
  const { root, wrapper } = usePreviewStyles();

  return (
    <div className={wrapper}>
      <div className={root}>
        <div className="content">
          {content.map(el => (
            <div
              className="content-block"
              key={`content-${el[0].id}`}
              style={{ display: el.length > 1 ? 'flex' : 'block' }}
            >
              {el
                .filter(
                  ({ type, advertisement }) =>
                    type && (type !== ADVERTISEMENT || !advertisement.isOffline),
                )
                .map(item => {
                  const Component = contentItems[item.type];

                  return (
                    <Component
                      key={item.id}
                      name={item.type}
                      variant={item.variant}
                      value={item[item.type]}
                      columnCount={el.length}
                      isSingleColumn={el.length === 1}
                    />
                  );
                })}
            </div>
          ))}
        </div>
        <MUIButton
          color="primary"
          variant="contained"
          className="close-button"
          onClick={onClosePreview}
        >
          Close preview
        </MUIButton>
      </div>
    </div>
  );
};

export default memo(Preview);
