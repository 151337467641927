import { memo } from 'react';
import clsx from 'clsx';

import { useButtonStyles } from './styles';

const Button = ({ value, columnCount }) => {
  const { root } = useButtonStyles();

  return (
    <div style={columnCount === 3 ? { width: '224px' } : {}}>
      <a className={clsx(root, value.variant)} href={value.url} target={value.target}>
        {value.text}
      </a>
    </div>
  );
};

export default memo(Button);
