import { FaTrash } from 'react-icons/fa';

import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';

import clsx from 'clsx';
import { useStyles } from './styles';

const ContentBlock = ({
  onMove,
  children,
  onDelete,
  columnCount,
  customWidth,
  isSingleColumn,
  disabledUpButton,
  disabledDownButton,
  withActions = true,
  renderContentCenter,
  showOnlyDeleteButton,
}) => {
  const { container, addButtonContainer, controlButton, controlButtons, deleteButton } = useStyles({
    columnCount,
    customWidth,
  });

  return (
    <div className={clsx(container, clsx({ flex: renderContentCenter }))}>
      <div className={clsx({ [addButtonContainer]: renderContentCenter })}>{children}</div>
      <div className={controlButtons}>
        {withActions && (
          <>
            {!showOnlyDeleteButton && (
              <>
                <IoIosArrowDropdown
                  size={38}
                  className={controlButton}
                  onClick={() => !disabledDownButton && onMove('down')}
                />
                <IoIosArrowDropup
                  size={38}
                  className={controlButton}
                  onClick={() => !disabledUpButton && onMove('up')}
                />
              </>
            )}
            <div onClick={onDelete} className={clsx(deleteButton)} aria-label="Delete">
              <FaTrash />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentBlock;
