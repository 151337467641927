import clsx from 'clsx';
import { memo } from 'react';

import Button from './Button';
import { useImageStyles } from './styles';

const Image = ({ value, isAdView, isSingleColumn, className, columnCount }) => {
  const { root } = useImageStyles({ columnCount });

  return (
    <div className={clsx(root, className, { center: !isAdView && isSingleColumn })}>
      {value.url && <img className={clsx({ mb16: value.button })} src={value.url} alt="Preview" />}
      {value.button && <Button value={value.button} />}
    </div>
  );
};

export default memo(Image);
