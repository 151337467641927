import { makeStyles } from '@material-ui/core';

import { IMAGE_TOP } from 'app/pages/SEOStatic/utils';

export const usePreviewStyles = makeStyles(() => ({
  wrapper: {
    top: 0,
    left: '50%',
    width: '100%',
    height: '100%',
    padding: '5px',
    zIndex: 9999,
    position: 'absolute',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    '& .mb16': {
      marginBottom: '16px !important',
    },
    padding: '5px',
    position: 'absolute',
    top: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,

    '& .content-block': {
      justifyContent: 'space-between',
      margin: '24px 0',
      gap: 10,
      '& p': {
        margin: 0,
      },
    },

    height: '100%',
    width: '820px',
    '& .content': {
      background: '#061E5A',
      padding: '24px 40px',
      color: 'white',
      position: 'relative',
      height: '660px',
      overflow: 'scroll',
      fontSize: '16px',
    },
    '& .close-button': {
      top: '-40px',
      right: '10px',
      position: 'absolute',
      backgroundColor: '#29C0A3',
    },
  },
}));

export const useAdvertisementStyles = makeStyles(() => ({
  root: {
    alignItems: 'flex-start',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
    '& .textCenter': {
      textAlign: 'center',
    },
    '& h2': {
      margin: 0,
      fontSize: '20px',
    },
    '& .imageCenter': {
      margin: 'auto',
    },
    display: ({ variant }) => (variant === IMAGE_TOP ? 'block' : 'flex'),
    color: 'white',
    gap: '24px',
  },

  content: {
    textAlign: 'left',
  },
}));

export const useTitleStyles = makeStyles(() => ({
  title: {
    margin: 0,
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
  },
}));

export const useImageStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    '&.center': {
      width: '100%',
      height: '190px',
      margin: '0 auto',
    },
    width: ({ columnCount }) => (columnCount === 3 ? '224px' : '348px'),

    flexShrink: 0,
    '& img': {
      height: '284px',
      width: '100%',
      height: ({ columnCount }) => (columnCount === 3 ? '183px' : '284px'),
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },
}));

export const useDescriptionStyles = makeStyles(() => ({
  root: {
    '& a': {
      color: '#6DB9FF',
    },
  },
}));

export const useButtonStyles = makeStyles(() => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'center',
    padding: '12px 0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    gap: '10px',
    textDecoration: 'none',
    color: 'white !important',
    '&.cta': {
      background: '#65AE00',
    },
    '&.primary': {
      background: '#488DE7',
    },
    '&.secondary': {
      background: '#003F8F',
    },
  },
}));

export const useDividerStyles = makeStyles(() => ({
  root: {
    border: 0,
    padding: 0,
    height: '1px',
    display: 'block',
    borderTop: '1px solid #1B509F',
    borderBottom: '1px solid #1B509F',
  },
}));
