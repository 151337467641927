import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import Menu from 'app/components/Menu';
import { mediaService } from 'services';
import { getCMSImageUrl } from 'utils/getImageUrl';
import FileUploader from 'app/components/FileUploader';
import { throwErrorActions } from 'store/slices/throwError';
import { ReactComponent as FaClose } from 'images/faClose.svg';

import Buttons from './Buttons';
import { buttonOptions, defaultButtonValues } from '../../utils';
import clsx from 'clsx';

const Image = ({
  handleChange,
  name,
  value,
  isSingleColumn,
  isContentBlock,
  withButtonsBlock = true,
}) => {
  const dispatch = useDispatch();

  const onChange = async file => {
    try {
      const fd = new FormData();
      fd.set('files', file);
      const {
        data: { ids },
      } = await mediaService.uploadImage({ file: fd, purpose: 'cms' });
      const { data } = await mediaService.getImage(ids[0]);
      const image = getCMSImageUrl(data);
      handleChange({ [name]: { url: image }, isImage: true });
      dispatch(throwErrorActions.updateSuccess('Image has been successfully uploaded!'));
    } catch (error) {
      dispatch(throwErrorActions.updateMessage({ message: 'Something went wrong...' }));
    }
  };

  return (
    <div className="image-block">
      {value?.url ? (
        <div
          className="image"
          style={{
            position: 'relative',
          }}
        >
          <img
            src={value.url}
            alt={value.name}
            style={{ ...(isContentBlock && { height: '190px', objectFit: 'cover' }) }}
          />
          <div className="icon-wrapper" onClick={() => handleChange({ [name]: { url: '' } })}>
            <FaClose />
          </div>
        </div>
      ) : (
        <FileUploader
          width="100%"
          height="290px"
          isSingleImage
          placeholder={
            <div className="image-placeholder">
              <FaCloudUploadAlt size="30" />
              <div>Upload Image</div>
            </div>
          }
          onChange={([{ file }]) => onChange(file)}
        />
      )}
      {withButtonsBlock && (
        <div className={clsx('button-block', { 'flex-center': !value.button })}>
          {value.button ? (
            <Buttons
              name="button"
              value={value.button}
              isSingleColumn={isSingleColumn}
              onDelete={() => handleChange({ [name]: { button: null } })}
              handleChange={button => handleChange({ [name]: button })}
            />
          ) : (
            <Menu
              handleClick={({ type }) =>
                handleChange({
                  [name]: { button: { ...defaultButtonValues, variant: type } },
                })
              }
              trigger={<BsFillPlusCircleFill size={30} color="#28C0A3" />}
              menu={buttonOptions}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Image);
