import axios from 'axios';
import options from 'config';
import jwt_decode from 'jwt-decode';
import cookies from 'js-cookie';

import { getFromStorage, removeFromStorage } from 'utils/persist';

const whiteList = [
  'account/configs',
  'profile/configs',
  'account/tokens/issue',
  'account/auth/tokens',
  'profile-verification/configs',
  'profile-ad/configs',
  'inventory/categories/sections',
];

export const HttpRequest = axios.create({
  baseURL: options.API_BASE_URL,
});

HttpRequest.interceptors.request.use(
  async configs => {
    let token = cookies.get('authToken');

    if (whiteList.every(e => configs?.url?.indexOf(e) === -1)) {
      if (token) {
        const { exp } = jwt_decode(token);
        if (Date.now() > exp * 1000) {
          const refreshToken = getFromStorage('authRefreshToken');

          const {
            data: { access_token },
          } = await axios.post(`${options.API_BASE_URL}/account/auth/refresh`, undefined, {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          });

          cookies.set('authToken', access_token);
          token = access_token;
        }
      }

      configs.headers.Authorization = `Bearer ${token}`;
    }
    return {
      ...configs,
    };
  },
  err => {
    return err;
  },
);

HttpRequest.interceptors.response.use(
  res => res,
  err => {
    if (err.response?.status === 401) {
      cookies.remove('authToken');
      removeFromStorage('authRefreshToken');
      window.location.href = 'login';
    } else if (err.response?.status === 404) {
      window.location.href = '/404';
    }
    throw err;
  },
);

export default HttpRequest;
